// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// Run `yarn run download-resources` to regenerate.
// If you need to update the code, please edit `i18n/src/scripts/download.ts` inside your project.
import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import ja from './ja.json';
import ko from './ko.json';
import zh_Hans from './zh-Hans.json';

export const LOCALES = [
  {
    id: 1000040010,
    name: 'Korean (South Korea)',
    tag: 'ko',
    originalName: '한국어(대한민국)',
    flagEmoji: '🇰🇷',
    base: false,
    completeRate: 0.663,
    res: ko,
  },
  {
    id: 1000040001,
    name: 'English',
    tag: 'en',
    originalName: 'English',
    flagEmoji: '🇬🇧',
    base: true,
    completeRate: 1,
    res: en,
  },
  {
    id: 1000040004,
    name: 'Simplified Chinese',
    tag: 'zh-Hans',
    originalName: '简体中文',
    flagEmoji: '🇨🇳',
    base: false,
    completeRate: 1,
    res: zh_Hans,
  },
  {
    id: 1000040006,
    name: 'French',
    tag: 'fr',
    originalName: 'français',
    flagEmoji: '🇫🇷',
    base: false,
    completeRate: 0.99,
    res: fr,
  },
  {
    id: 1000040009,
    name: 'German',
    tag: 'de',
    originalName: 'Deutsch',
    flagEmoji: '🇩🇪',
    base: false,
    completeRate: 0.758,
    res: de,
  },
  {
    id: 1000040014,
    name: 'Japanese',
    tag: 'ja',
    originalName: '日本語',
    flagEmoji: '🇯🇵',
    base: false,
    completeRate: 0.738,
    res: ja,
  },
] as const;
